<template>
  <div>
    <input
      ref="input"
      type="text"
      class="form-input w-full"
      :placeholder="placeholder"
      :value="modelValue"
      @change="change"
    >
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import Tagify from '@yaireo/tagify';
import {isEqual} from 'lodash';
import {usePage} from '@inertiajs/vue3';

export default {
  props: {
    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    modelValue: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  emits: ['update:modelValue'],

  data () {
    const algoliaClient = algoliasearch(usePage().props.algolia.app_id, usePage().props.algolia.app_key);

    return {
      algoliaKeywordIndex: algoliaClient.initIndex('keywords'),
      previousValue: null,
      timeout: null,

      tagifySettings: {
        whitelist: [],

        dropdown: {
          enabled: 0,
          searchKeys: [],
        },

        callbacks: {
          input: this.tagifyInput,
        },
      },
    };
  },

  mounted () {
    this.tagify = new Tagify(this.$refs.input, this.tagifySettings);
  },

  methods: {
    change(e) {
      let decodedValue = null;

      if (e.target.value === '') {
        this.$emit('update:modelValue', []);
        return;
      }

      try {
        decodedValue = JSON.parse(e.target.value);
      } catch {
        return;
      }

      if (!decodedValue || !Array.isArray(decodedValue)) {
        return;
      }

      const newValue = decodedValue.map((item) => item.value);
      if (this.previousValue && isEqual(newValue.sort(), this.previousValue.sort())) {
        return;
      }

      this.previousValue = newValue;
      this.$emit('update:modelValue', newValue);
    },

    tagifyInput(e) {
      clearTimeout(this.timeout);

      const value = e.detail.value;
      this.tagify.whitelist = null;
      this.tagify.loading(true).dropdown.hide();

      this.timeout = setTimeout(() => {
        this.loadKeywords(value).then((results) => {
          this.tagify.whitelist = results;
          this.tagify.loading(false).dropdown.show(value);
        });
      }, 500);
    },

    loadKeywords(query) {
      return new Promise((resolve, reject) => {
        if (!query || query.length === 0) {
          resolve([]);
          return;
        }

        this.algoliaKeywordIndex.search(query, {
          facetFilters: ['countries:us'],
        })
          .then((result) => resolve(result.hits.map((value) => value.name)))
          .catch(reject);
      });
    },
  },
};
</script>
