import { usePage } from '@inertiajs/vue3';

export default {
  data() {
    return {
      broadcastChannel: null,
    };
  },

  destroyed() {
    if (this.broadcastChannel) {
      this.broadcastChannel.close();
    }
  },

  methods: {
    createBroadcastChannel() {
      if (!this.broadcastChannel) {
        this.broadcastChannel = new BroadcastChannel('service-worker');
        this.broadcastChannel.addEventListener('message', (event) => {
          this.handleServiceWorkerMessage(event.data);
        });
      }
    },

    handleServiceWorkerMessage(data) {
      if (data.type === 'GET_SUBSCRIPTION' && typeof this.handlePushSubscriptionData === 'function') {
        this.handlePushSubscriptionData(data.data);
      }
      if (data.type === 'SUBSCRIBE_JOB_SEEKER' && typeof this.handlePushSubscribe === 'function') {
        this.handlePushSubscribe(data.data);
      }
      if (data.type === 'UNSUBSCRIBE_JOB_SEEKER' && typeof this.handlePushUnsubscribe === 'function') {
        this.handlePushUnsubscribe(data.data);
      }
    },

    getPushPermission() {
      if ('Notification' in window) {
        return Notification.permission;
      }

      return false;
    },

    askPushNotificationPermission() {
      if ('Notification' in window) {
        // value of permission can be 'granted', 'default', 'denied'
        // granted: user has accepted the request
        // default: user has dismissed the notification permission popup by clicking on x
        // denied: user has denied the request.
        return window.Notification.requestPermission();
      }

      return false;
    },

    getPushSubscription() {
      if (this.$swRegistration.active) {
        this.createBroadcastChannel();

        this.$swRegistration.active.postMessage({
          type: 'GET_SUBSCRIPTION',
        });
      }
    },

    pushSubscribe() {
      if (this.$swRegistration.active) {
        this.createBroadcastChannel();
        this.$swRegistration.active.postMessage({
          type: 'SUBSCRIBE_JOB_SEEKER',
          jobSeeker: {
            ip: usePage().props.requestData.ip,
            userAgent: usePage().props.requestData.userAgent,
          },
          auth: {
            id: usePage().props.jobSeeker.id,
            hash: usePage().props.jobSeeker.hash,
          },
        });
      }
    },

    pushUnsubscribe() {
      if (this.$swRegistration.active) {
        this.createBroadcastChannel();

        this.$swRegistration.active.postMessage({
          type: 'UNSUBSCRIBE_JOB_SEEKER',
          jobSeeker: {
            ip: usePage().props.requestData.ip,
            userAgent: usePage().props.requestData.userAgent,
          },
          auth: {
            id: usePage().props.jobSeeker.id,
            hash: usePage().props.jobSeeker.hash,
          },
        });
      }
    },
  },
};
