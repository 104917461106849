<template>
  <div class="rounded-t-md border border-gray-300 p-4 md:p-8">
    <div class="font-semibold text-3xl">
      {{ __('profile.notifications.title') }}
    </div>
  </div>

  <div class="rounded-b-md border border-t-0 border-gray-300 px-4 md:px-8">
    <div class="py-4 divide-y divide-gray-200">
      <div class="flex justify-between items-center py-4">
        <template v-if="!jobSeeker.email">
          <div class="font-semibold text-2xl">
            {{ __('profile.notifications.email.denied') }}
          </div>
        </template>
        <template v-else>
          <div class="font-semibold text-2xl">
            {{ __('profile.notifications.email.title') }}
          </div>

          <div>
            <template v-if="!jobSeeker.unsubscribed && jobSeeker.email">
              <button
                type="button"
                class="border bg-danger hover:bg-danger/80 text-white transition-colors text-sm md:text-base px-2 py-1 md:px-4 md:py-2 rounded whitespace-nowrap disabled:bg-danger/40"
                :disabled="loading"
                @click="unsubscribe('email')"
              >
                <span class="fa fa-times mr-1" />
                {{ __('profile.notifications.email.unsubscribe') }}
              </button>
            </template>
            <template v-else>
              <button
                type="button"
                class="border bg-success hover:bg-success/80 text-white transition-colors text-sm md:text-base px-2 py-1 md:px-4 md:py-2 rounded whitespace-nowrap disabled:bg-success/40"
                :disabled="loading"
                @click="subscribe('email')"
              >
                <span class="fa fa-check mr-1" />
                {{ __('profile.notifications.email.subscribe') }}
              </button>
            </template>
          </div>
        </template>
      </div>

      <template v-if="showSmsNotification">
        <div
          class="flex py-4"
          :class="jobSeeker.mobilePhoneNumber ? 'justify-between items-center' : 'flex-col'"
        >
          <div class="font-semibold text-2xl">
            {{ __('profile.notifications.sms.title') }}
          </div>

          <template v-if="!jobSeeker.mobilePhoneNumber">
            <div class="mt-4">
              {{ __('profile.notifications.sms.denied') }}
            </div>
          </template>
          <template v-else>
            <div>
              <template v-if="jobSeeker.smsOptIn">
                <button
                  type="button"
                  class="border bg-danger hover:bg-danger/80 text-white transition-colors text-sm md:text-base px-2 py-1 md:px-4 md:py-2 rounded whitespace-nowrap disabled:bg-danger/40"
                  :disabled="loading"
                  @click="unsubscribe('sms')"
                >
                  <span class="fa fa-times mr-1" />
                  {{ __('profile.notifications.sms.unsubscribe') }}
                </button>
              </template>
              <template v-else>
                <button
                  type="button"
                  class="border bg-success hover:bg-success/80 text-white transition-colors text-sm md:text-base px-2 py-1 md:px-4 md:py-2 rounded whitespace-nowrap disabled:bg-success/40"
                  :disabled="loading"
                  @click="subscribe('sms')"
                >
                  <span class="fa fa-check mr-1" />
                  {{ __('profile.notifications.sms.subscribe') }}
                </button>
              </template>
            </div>
          </template>
        </div>
      </template>

      <template v-if="showPushNotification">
        <div class="py-4">
          <div class="font-semibold text-2xl mb-2">
            {{ __('profile.notifications.push.title') }}
          </div>

          <template v-if="pushDenied">
            <div class="py-4 px-6 mt-4 mb-2 bg-danger/40 text-red-900 rounded-md">
              {{ __('profile.notifications.push.denied') }}
            </div>
          </template>

          <template v-if="canPushSubscribeThisDevice">
            <div class="flex justify-between items-center py-1">
              <div>
                {{ __('profile.notifications.push.current', getOwnPushSubscriptionInfo()) }}
              </div>
              <button
                type="button"
                class="border bg-success hover:bg-success/80 text-white transition-colors text-sm md:text-base px-2 py-1 md:px-4 md:py-2 rounded whitespace-nowrap disabled:bg-success/40"
                :disabled="loading"
                @click="subscribePush"
              >
                <span class="fa fa-check mr-1" />
                {{ __('profile.notifications.push.subscribe') }}
              </button>
            </div>
          </template>

          <template
            v-for="(pushSubscription, i) in jobSeeker.pushSubscriptions ?? []"
            :key="`push-subscription-${i}`"
          >
            <div class="flex justify-between items-center py-1">
              <div>
                {{ __('profile.notifications.push.info', getPushSubscriptionInfo(pushSubscription)) }}
              </div>
              <button
                type="button"
                class="border bg-danger hover:bg-danger/80 text-white transition-colors text-sm md:text-base px-2 py-1 md:px-4 md:py-2 rounded whitespace-nowrap disabled:bg-danger/40"
                :disabled="loading"
                @click="unsubscribePush(pushSubscription)"
              >
                <span class="fa fa-times mr-1" />
                {{ __('profile.notifications.push.unsubscribe') }}
              </button>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>

  <SubscribedModal ref="subscribed-modal" />
  <UnsubscribeModal ref="unsubscribe-modal" />
  <UnsubscribedModal ref="unsubscribed-modal" />
  <ErrorModal ref="error-modal" />
</template>

<script>
import { usePage } from '@inertiajs/vue3';
import { UAParser } from 'ua-parser-js';
import moment from 'moment';
import DefaultLayout from '../../../Layouts/Default.vue';
import ProfileLayout from '../../../Layouts/Profile.vue';
import PushNotification from '../../../Mixins/PushNotification';
import SubscribedModal from './Components/SubscribedModal.vue';
import UnsubscribeModal from './Components/UnsubscribeModal.vue';
import UnsubscribedModal from './Components/UnsubscribedModal.vue';
import ErrorModal from '../../Auth/Components/ErrorModal.vue';


export default {
  components: {
    SubscribedModal,
    UnsubscribeModal,
    UnsubscribedModal,
    ErrorModal,
  },

  mixins: [PushNotification],

  layout: [DefaultLayout, ProfileLayout],

  setup() {
    const config = usePage().props.config;
    return {
      showSmsNotification: config.showSmsNotification,
      showPushNotification: config.showPushNotification,
    };
  },

  data() {
    return {
      loading: false,

      canPushSubscribeThisDevice: false,
      pushDenied: false,
    };
  },

  computed: {
    jobSeeker() {
      return usePage().props.jobSeeker;
    },
  },

  mounted() {
    this.getPushSubscription();
  },

  methods: {
    subscribe(type) {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$inertia.post(this.route(`frontend.profile.notifications.${type}`), {
        subscribe: true,
      }, {
        onSuccess: () => {
          this.$refs['subscribed-modal'].open();
        },
        onError: () => {
          this.$refs['error-modal'].open();
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },

    unsubscribe(type) {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$refs['unsubscribe-modal'].open({
        cancel: () => {
          this.loading = false;
        },
        unsubscribe: () => {
          this.$inertia.post(this.route(`frontend.profile.notifications.${type}`), {
            subscribe: false,
          }, {
            onSuccess: () => {
              this.$refs['unsubscribed-modal'].open();
            },
            onError: () => {
              this.$refs['error-modal'].open();
            },
            onFinish: () => {
              this.$refs['unsubscribe-modal'].close();
              this.loading = false;
            },
          });
        },
      });
    },

    subscribePush() {
      this.loading = true;

      this.askPushNotificationPermission()
        .then((answer) => {
          if (answer === 'granted') {
            this.pushDenied = false;
            this.pushSubscribe();
            this.$refs['subscribed-modal'].open();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            this.pushDenied = true;
          }
        })
        .catch(() => {
          this.$refs['error-modal'].open();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    unsubscribePush(pushSubscription) {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$refs['unsubscribe-modal'].open({
        cancel: () => {
          this.loading = false;
        },
        unsubscribe: () => {
          this.$inertia.post(this.route('frontend.profile.notifications.push'), {
            pushSubscription: {
              ip: pushSubscription.ip,
              userAgent: pushSubscription.userAgent,
              endpoint: pushSubscription.endpoint,
              p256dhKey: pushSubscription.p256dhKey,
              authSecret: pushSubscription.authSecret,
            },
          }, {
            onSuccess: () => {
              this.$refs['unsubscribed-modal'].open();
              window.location.reload();
            },
            onError: () => {
              this.$refs['error-modal'].open();
            },
            onFinish: () => {
              this.$refs['unsubscribe-modal'].close();
              this.loading = false;
            },
          });
        },
      });
    },

    getOwnPushSubscriptionInfo() {
      const agent = new UAParser(usePage().props.requestData.userAgent);
      return {
        name: agent.getBrowser().name + ' ' + agent.getBrowser().version,
        os: agent.getOS().name + ' ' + agent.getOS().version,
      };
    },

    getPushSubscriptionInfo(pushSubscription) {
      const agent = new UAParser(usePage().props.requestData.userAgent);
      return {
        name: agent.getBrowser().name + ' ' + agent.getBrowser().version,
        os: agent.getOS().name + ' ' + agent.getOS().version,
        at: moment(pushSubscription).format('YYYY-MM-DD'),
      };
    },

    handlePushSubscriptionData(subscription) {
      if (subscription === null) {
        this.canPushSubscribeThisDevice = true;
      } else {
        const pushSubscriptions = this.jobSeeker.pushSubscriptions ?? [];
        this.canPushSubscribeThisDevice = !pushSubscriptions.find((value) => value.endpoint === subscription.endpoint);
      }
    },

    handlePushSubscribe() {
      this.canPushSubscribeThisDevice = false;
    },
  },
};
</script>
