<template>
  <InertiaHead :title="__('contact.title')" />

  <main class="container md:px-12 mt-14 md:mt-18">
    <h1 class="font-bold text-4xl mb-4">
      {{ __('contact.title') }}
    </h1>

    <div class="mb-8">
      <i18n-t
        keypath="contact.text"
        scope="global"
      >
        <template #link>
          <InertiaLink
            :href="route('frontend.faq')"
            class="text-primary hover:text-primary-dark transition-colors underline hover:underline-none"
          >
            {{ __('contact.link.text') }}
          </InertiaLink>
        </template>
      </i18n-t>
    </div>

    <form @submit.prevent="send">
      <div class="space-y-4">
        <div class="md:grid md:grid-cols-12 md:items-center md:gap-x-6">
          <label
            for="name"
            class="md:col-span-3"
          >
            {{ __('contact.name-input.label') }}:
          </label>
          <input
            id="name"
            v-model="form.name"
            type="text"
            autocomplete="name"
            class="w-full md:col-span-5"
            required
          >
        </div>

        <div class="md:grid md:grid-cols-12 md:items-center md:gap-x-6">
          <label
            for="email"
            class="md:col-span-3"
          >
            {{ __('contact.email-input.label') }}:
          </label>
          <input
            id="email"
            v-model="form.email"
            type="email"
            autocomplete="email"
            class="w-full md:col-span-5"
            required
          >
        </div>

        <div class="md:grid md:grid-cols-12 md:items-baseline md:gap-x-6">
          <label
            for="message"
            class="md:col-span-3"
          >
            {{ __('contact.message-input.label') }}:
          </label>
          <textarea
            id="message"
            v-model="form.message"
            rows="5"
            class="w-full md:col-span-9"
            required
          />
        </div>

        <div class="grid grid-cols-12">
          <div class="md:col-start-4 col-span-12 md:col-span-10 md:ml-1.5">
            <button
              type="submit"
              class="w-full sm:w-auto bg-success hover:bg-success/80 text-white transition-colors px-4 py-2 rounded whitespace-nowrap disabled:bg-success/40"
              :disabled="loading"
            >
              <span class="fa fa-paper-plane mr-2" />
              {{ __('contact.send-button.idle') }}
              <span
                v-show="loading"
                class="fa fa-spinner animate-spin ml-1"
              />
            </button>
          </div>
        </div>
      </div>
    </form>
  </main>

  <template v-if="$page.props.flash.success">
    <ContactSentModal :initial-open="true" />
  </template>
  <ErrorModal ref="error-modal" />
</template>

<script>
import { useForm, usePage } from '@inertiajs/vue3';
import ContactSentModal from './Components/ContactSentModal.vue';
import ErrorModal from '../Auth/Components/ErrorModal.vue';

export default {
  components: {
    ContactSentModal,
    ErrorModal,
  },

  setup() {
    const form = useForm({
      name: '',
      email: usePage().props.jobSeeker?.email ?? '',
      message: '',
    });

    return {
      form,
    };
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    send() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.form.post(this.route('frontend.contact.send'), {
        onSuccess: () => {
          this.form.reset();
        },
        onError: () => {
          this.$refs['error-modal'].open();
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
};
</script>
