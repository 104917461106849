<template>
  <main class="container">
    <Breadcrumbs @search="loadJobs" />

    <MobileFilterSlider @search="loadJobs">
      <template #button="{ open }">
        <button
          type="button"
          class="md:hidden border border-controls hover:bg-controls text-controls hover:text-white transition-colors px-2 py-1 mb-8 rounded"
          @click="open"
        >
          {{ __('job-list.filters.title') }}
          <span class="fa fa-chevron-right ml-1" />
        </button>
      </template>
    </MobileFilterSlider>

    <div class="flex">
      <DesktopFilterBar @search="loadJobs" />

      <div class="w-full lg:w-3/4 lg:ml-8">
        <OptInReminder />

        <div class="flex justify-between items-center mb-4 md:mb-8">
          <h1
            v-show="showSearchTitle"
            class="font-bold text-2xl md:text-3xl"
          >
            {{ searchTitle }}
          </h1>

          <div class="text-right grow">
            <button
              type="button"
              class="border border-controls hover:bg-controls text-controls hover:text-white transition-colors px-2 py-1 md:px-4 md:py-2 rounded"
              @click="reset"
            >
              {{ __('job-list.header.search-again') }}
            </button>
          </div>
        </div>

        <div
          v-show="loading"
          class="border-t border-gray-200"
        >
          <div class="mt-14 text-center">
            <span class="fa fa-spinner-third animate-spin text-6xl text-primary" />
          </div>
        </div>
        <div v-show="!loading">
          <div class="border-y border-gray-200 divide-y-2 divide-gray-200 mb-8">
            <template v-if="$store.state.Index.job">
              <Job
                :index="0"
                :job="$store.state.Index.job"
                :highlighted="true"
              />
            </template>

            <template v-if="jobs.length > 0">
              <template
                v-for="(job, i) in jobs"
                :key="`job-${i}`"
              >
                <Job
                  :index="i + 1"
                  :job="job"
                />
                <div
                  v-if="i === 0"
                  class="adsense"
                >
                  <div id="top-ads" />
                </div>
                <div
                  v-if="jobs.length > 3 && i === jobs.length-2"
                  class="adsense"
                >
                  <div id="bottom-ads" />
                </div>
              </template>
            </template>
            <template v-else>
              <div class="py-6 text-center">
                {{ __('job-list.no-results') }}
              </div>
            </template>
          </div>

          <div class="text-center">
            <nav
              v-show="jobs.length > 0"
              class="inline-flex space-x-1"
            >
              <button
                type="button"
                class="inline-flex items-center rounded px-2 py-2 text-gray-400 border border-gray-300 hover:border-primary hover:text-primary transition-colors hover:no-underline"
                :disabled="currentPage === 1"
                @click="previousPage"
              >
                <span class="fal fa-chevron-left px-1" />
              </button>

              <template
                v-for="(page, i) in pagination"
                :key="`page-${page}`"
              >
                <button
                  type="button"
                  class="inline-flex items-center px-3 py-2 text-sm text-gray-400 border border-gray-300 rounded hover:border-controls hover:text-controls transition-colors hover:no-underline"
                  :class="{
                    'bg-controls !text-white !hover:text-white': page === currentPage,
                    'hidden md:block': i >= 6,
                  }"
                  @click="paginate(page)"
                >
                  {{ page }}
                </button>
              </template>

              <button
                type="button"
                class="inline-flex items-center rounded px-2 py-2 text-gray-400 border border-gray-300 hover:border-controls hover:text-controls transition-colors hover:no-underline"
                :disabled="Math.ceil(total / limit) === currentPage"
                @click="nextPage"
              >
                <span class="fal fa-chevron-right px-1" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import {usePage} from '@inertiajs/vue3';
import {upperFirst, lowerCase, cloneDeep} from 'lodash';
import MobileFilterSlider from './MobileFilterSlider.vue';
import DesktopFilterBar from './DesktopFilterBar.vue';
import Breadcrumbs from './Breadcrumbs.vue';
import OptInReminder from '../../../../Components/OptInReminder/OptInReminder.vue';
import Job from './Job.vue';
import Search from '../../../../Mixins/Search.js';

export default {
  components: {
    DesktopFilterBar,
    MobileFilterSlider,
    Breadcrumbs,
    OptInReminder,
    Job,
  },

  mixins: [Search],

  props: {
    channel: {
      required: false,
      type: String,
      default: null,
    },
  },

  setup() {
    let engine = '';
    let currentPage = 1;

    if (typeof location !== 'undefined') {
      const urlQuery = new URLSearchParams(location.search);
      engine = urlQuery.has('se') ? urlQuery.get('se') : '';
      currentPage = urlQuery.has('page') ? parseInt(urlQuery.get('page')) : 1;
    }

    return {engine, currentPage};
  },

  data() {
    return {
      loading: true,
      limit: 10,

      jobs: [],
      total: 0,
    };
  },

  computed: {
    showSearchTitle() {
      const jobSearch = this.$store.state.Index.jobSearch;
      return (jobSearch.keywords && jobSearch.keywords.length > 0) || jobSearch.location.country;
    },

    searchTitle() {
      const formatLocation = (location) => {
        if (location.postalCode && location.country) {
          return `${location.postalCode}, ${location.country.toUpperCase()}`;
        } else if (location.postalCode) {
          return location.postalCode;
        } else if (location.city && location.country) {
          return `${location.city}, ${location.country.toUpperCase()}`;
        } else if (location.city) {
          return location.city;
        } else if (location.state && location.country) {
          return `${location.state.toUpperCase()}, ${location.country.toUpperCase()}`;
        } else if (location.state) {
          return location.state.toUpperCase();
        } else if (location.country) {
          return location.country.toUpperCase();
        }

        return null;
      };

      const jobSearch = this.$store.state.Index.jobSearch;
      const keywords = jobSearch.keywords ? jobSearch.keywords.join(',') : [];
      const location = formatLocation(jobSearch.location);

      // If we have a location but the keyword is empty
      if (keywords.length === 0) {
        return upperFirst(this.__('job-list.no-keywords', { location }));
      }

      return upperFirst(this.__('job-list.query', {
        query: lowerCase(keywords),
        location,
      }));
    },

    hasJobSearch() {
      return !!(
        (this.$store.state.Index.jobSearch.keywords && this.$store.state.Index.jobSearch.keywords.length > 0)
        || (this.$store.state.Index.jobSearch.location && this.$store.state.Index.jobSearch.location.country)
      );
    },

    pagination () {
      const totalPages = Math.ceil(this.total / this.limit);
      const offset = 5;

      if (totalPages <= 0) {
        return [];
      }

      let from = this.currentPage - offset;
      if (from < 1) {
        from = 1;
      }

      let to = from + (offset * 2);
      if (to >= totalPages) {
        to = totalPages;
      }

      const pages = [];
      for (let i = from; i <= to; i++) {
        pages.push(i);
      }

      return pages;
    },
  },

  mounted() {
    this.loadJobs();
  },

  methods: {
    updateUrl(clear = false) {
      let urlQuery = new URLSearchParams(location.search);
      if (clear) {
        urlQuery = new URLSearchParams();
      }

      const query = Object.fromEntries(urlQuery);
      query['se'] = this.engine;

      if (this.hasJobSearch) {
        const jobSearch = this.$store.state.Index.jobSearch;
        const jobSearchQuery = cloneDeep(this.jobSearchToQuery(jobSearch));
        for (const key in jobSearchQuery) {
          query[key] = jobSearchQuery[key];
        }
        query['page'] = this.currentPage > 1 ? this.currentPage : null;
      }

      // Remove empty values from query
      for (const key in query) {
        if (!query[key]) {
          delete query[key];
        }
      }

      const newUrl = new URL(location);
      newUrl.search = new URLSearchParams(query).toString();

      this.$inertia.visit(newUrl, {
        preserveState: true,
        preserveScroll: true,
      });
    },

    reset() {
      this.$inertia.visit(this.route('frontend.index'));
    },

    loadJobs() {
      this.loading = true;

      const jobSearch = this.$store.state.Index.jobSearch;
      const requestData = usePage().props.requestData;

      axios.post(this.route('frontend.api.jobs.index'), {
        jobSearch,
        page: this.currentPage,
        limit: this.limit,
        engine: this.engine,
        userAgent: requestData.userAgent,
        ip: requestData.ip,
      })
        .then(({data}) => {
          this.engine = data.engine;
          this.jobs = data.jobs || [];
          this.total = data.totalResults;
        })
        .finally(() => {
          this.loading = false;
          this.updateUrl();

          this.$nextTick(() => {
            if (this.page === 1) {
              this.$tracker.search(
                jobSearch.keywords && jobSearch.keywords.length > 0 ? jobSearch.keywords.join(',') : null,
                jobSearch.location.city || null,
              );
            } else {
              this.$tracker.pageChange(
                this.page,
                jobSearch.keywords && jobSearch.keywords.length > 0 ? jobSearch.keywords.join(',') : null,
                jobSearch.location.city || null,
              );
            }
            this.loadAdsense(
              this.page,
              jobSearch.keywords && jobSearch.keywords.length > 0 ? jobSearch.keywords.join(',') : null,
              jobSearch.location.city || null,
            );
          });
        });
    },

    loadAdsense(page, query, city) {
      if (this.jobs.length > 1) {
        const numberOfAds = (window?.innerWidth < 500 || window?.innerHeight < 500) ? 3 : 4;
        const test = location?.hostname?.includes('localhost') ? 'yes' : 'no';

        const pageOptions = {
          'pubId': 'pub-7412579377061454',
          'query': `${query} jobs ${city}`,
          'adtest': test,
          'channel': this.channel,
          'hl': 'en',
          'adPage': page,
          'linkTarget' : '_blank',
        };

        const adBlocks = [];
        adBlocks.push({
          'container': 'top-ads',
          'number': numberOfAds,
          'width': '100%',
          'fontSizeTitle': 17,
          'fontSizeDescription': 14,
          'fontSizeDomainLink': 14,
          'colorBackground' : 'fbfbfb',
        });
        if (this.jobs.length > 3) {
          adBlocks.push({
            'container': 'bottom-ads',
            'number': numberOfAds,
            'width': '100%',
            'fontSizeTitle': 17,
            'fontSizeDescription': 14,
            'fontSizeDomainLink': 14,
            'colorBackground' : 'fbfbfb',
          });
        }
        // eslint-disable-next-line no-undef
        _googCsa('ads', pageOptions, ...adBlocks);
      }
    },

    previousPage() {
      this.currentPage -= 1;
      this.loadJobs();
    },

    nextPage() {
      this.currentPage += 1;
      this.loadJobs();
    },

    paginate(page) {
      this.currentPage = page;
      this.loadJobs();
    },
  },
};
</script>
