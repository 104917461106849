<template>
  <InertiaHead :title="__('general.title')" />

  <SerpIndex :channel="channel" />

  <OptInConfirmModal ref="optin-confirm" />
  <LoggedOutModal ref="logged-out" />
  <ErrorModal ref="error" />
</template>

<script>
import { usePage } from '@inertiajs/vue3';
import { useStore } from 'vuex';
import SerpIndex from './Components/Serp/Index.vue';
import OptInConfirmModal from './Components/Modals/OptInConfirmModal.vue';
import ErrorModal from '../Auth/Components/ErrorModal.vue';
import LoggedOutModal from './Components/Modals/LoggedOutModal.vue';

export default {
  components: {
    LoggedOutModal,
    ErrorModal,
    OptInConfirmModal,
    SerpIndex,
  },

  props: {
    jobRating: {
      required: false,
      type: String,
      default: null,
    },

    modal: {
      required: false,
      type: String,
      default: null,
    },

    channel: {
      required: false,
      type: String,
      default: null,
    },
  },

  setup(props) {
    useStore().commit('Index/setJob', usePage().props.job || null);
    useStore().commit('Index/setJobSearch', usePage().props.jobSearch);
    useStore().commit('Index/setJobRating', parseInt(props.jobRating));
  },

  mounted() {
    if (this.modal && this.modal in this.$refs) {
      this.$refs[this.modal].open();
    }

    if (this.modal === 'logged-out') {
      this.$tracker.logout();
    }
  },
};
</script>
