<template>
  <InertiaHead :title="__('privacy-policy.title')" />
  <main class="content container md:px-12 mt-8 md:mt-18">
    <h1>
      {{ __('privacy-policy.title') }}
    </h1>
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="__('privacy-policy.html')" />
    <!-- eslint-enable vue/no-v-html -->

    <p>
      <strong>{{ __('privacy-policy.contact_title') }}</strong><br>
      {{ __('privacy-policy.contact_form') }}:
      <InertiaLink
        :href="route('frontend.contact.index')"
        class="underline"
        target="_blank"
      >
        {{ __('privacy-policy.contact_form_text') }}.
      </InertiaLink><br>
      {{ __('privacy-policy.contact_email') }}: <a :href="'mailto:' + email">{{ email }}</a>
    </p>
  </main>
</template>

<script>
import {usePage} from '@inertiajs/vue3';
export default {
  setup() {
    return {
      email: usePage().props.config.email,
    };
  },
};
</script>
