<template>
  <InertiaHead :title="__('general.title')" />

  <DelayIndex />

  <OptInConfirmModal ref="optin-confirm" />
  <LoggedOutModal ref="logged-out" />
  <ErrorModal ref="error" />
</template>

<script>
import {useStore} from 'vuex';
import {usePage} from '@inertiajs/vue3';
import Clean from '../../Layouts/Clean.vue';
import DelayIndex from './Components/Delay/Index.vue';
import OptInConfirmModal from './Components/Modals/OptInConfirmModal.vue';
import ErrorModal from '../Auth/Components/ErrorModal.vue';
import LoggedOutModal from './Components/Modals/LoggedOutModal.vue';

export default {
  components: {
    LoggedOutModal,
    ErrorModal,
    OptInConfirmModal,
    DelayIndex,
  },

  layout: Clean,

  props: {
    modal: {
      required: false,
      type: String,
      default: null,
    },
  },

  setup() {
    useStore().commit('Index/setJob', usePage().props.job || null);
    useStore().commit('Index/setJobSearch', usePage().props.jobSearch);
  },

  mounted() {
    if (this.modal && this.modal in this.$refs) {
      this.$refs[this.modal].open();
    }

    if (this.modal === 'logged-out') {
      this.$tracker.logout();
    }
  },
};
</script>
