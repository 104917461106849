<template>
  <div class="rounded-t-md border border-gray-300 p-4 md:p-8">
    <div class="font-semibold text-3xl mb-2">
      {{ __('profile.menu.information') }}
    </div>

    <div>
      {{ __('profile.information.edit-profile') }}
    </div>
  </div>

  <div class="rounded-b-md border border-t-0 border-gray-300 px-4 md:px-8">
    <div class="grid grid-cols-12 py-8 border-b border-gray-200">
      <div class="md:col-start-1 col-span-12 md:col-span-11 space-y-4">
        <div class="md:grid md:grid-cols-12 md:items-center md:gap-x-6">
          <label
            for="first_name"
            class="md:col-span-4 md:text-right"
          >
            {{ __('profile.information.first-name') }}
          </label>
          <div class="md:col-span-8">
            <input
              id="first_name"
              v-model="form.firstName"
              type="text"
              autocomplete="given-name"
              class="w-full"
              :class="$page.props.errors.firstName ? 'border-danger' : ''"
            >
            <small
              v-if="$page.props.errors.firstName"
              class="text-danger"
              v-text="$page.props.errors.firstName"
            />
          </div>
        </div>

        <div class="md:grid md:grid-cols-12 md:items-center md:gap-x-6">
          <label
            for="last_name"
            class="md:col-span-4 md:text-right"
          >
            {{ __('profile.information.last-name') }}
          </label>
          <div class="md:col-span-8">
            <input
              id="last_name"
              v-model="form.lastName"
              type="text"
              autocomplete="family-name"
              class="w-full"
              :class="$page.props.errors.lastName ? 'border-danger' : ''"
            >
            <small
              v-if="$page.props.errors.lastName"
              class="text-danger"
              v-text="$page.props.errors.lastName"
            />
          </div>
        </div>

        <div class="md:grid md:grid-cols-12 md:items-center md:gap-x-6">
          <label
            for="email"
            class="md:col-span-4 md:text-right"
          >
            {{ __('profile.information.email') }}
          </label>
          <div class="md:col-span-8">
            <input
              id="email"
              v-model="form.email"
              type="email"
              autocomplete="email"
              class="w-full"
              :class="$page.props.errors.email ? 'border-danger' : ''"
            >
            <small
              v-if="$page.props.errors.email"
              class="text-danger"
              v-text="$page.props.errors.email"
            />
          </div>
        </div>

        <div class="md:grid md:grid-cols-12 md:items-center md:gap-x-6">
          <label
            for="mobile_phone_number"
            class="md:col-span-4 md:text-right"
          >
            {{ __('profile.information.phone-number') }}
          </label>
          <div class="w-full md:col-span-8">
            <PhoneNumberInput
              v-model="form.mobilePhoneNumber"
              name="mobile_phone_number"
              :class="$page.props.errors.mobile_phone_number ? 'border-danger' : ''"
            />
            <small
              v-if="$page.props.errors.mobile_phone_number"
              class="text-danger"
              v-text="$page.props.errors.mobile_phone_number"
            />
          </div>
        </div>

        <div class="md:grid md:grid-cols-12 md:items-center md:gap-x-6">
          <label class="md:col-span-4 md:text-right">
            {{ __('profile.information.date-of-birth') }}
          </label>
          <div class="w-full md:col-span-8 flex space-x-4">
            <BirthdayInput
              v-model="form.birthday"
              :class="$page.props.errors.birthday ? 'border-danger' : ''"
            />
            <small
              v-if="$page.props.errors.birthday"
              class="text-danger"
              v-text="$page.props.errors.birthday"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 py-4">
      <div class="md:col-start-4 col-span-12 md:col-span-10 md:ml-11 sm:space-x-2 space-y-2 sm:space-y-0">
        <button
          type="button"
          class="w-full sm:w-auto bg-success hover:bg-success/80 text-white transition-colors px-4 py-2 rounded whitespace-nowrap disabled:bg-success/40"
          :disabled="loading"
          @click="save"
        >
          {{ __('profile.account-details.button') }}
          <span
            v-show="loading"
            class="fa fa-spinner animate-spin ml-1"
          />
        </button>
      </div>
    </div>
  </div>

  <template v-if="$page.props.flash.success">
    <SavedSuccessModal :initial-open="true" />
  </template>
  <ErrorModal ref="error-modal" />
</template>

<script>
import { useForm, usePage } from '@inertiajs/vue3';
import DefaultLayout from '../../../Layouts/Default.vue';
import ProfileLayout from '../../../Layouts/Profile.vue';
import SavedSuccessModal from '../Components/SavedSuccessModal.vue';
import ErrorModal from '../../Auth/Components/ErrorModal.vue';
import PhoneNumberInput from '../../../Components/PhoneNumberInput.vue';
import BirthdayInput from '../../../Components/BirthdayInput.vue';

export default {
  components: {
    BirthdayInput,
    PhoneNumberInput,
    SavedSuccessModal,
    ErrorModal,
  },

  layout: [DefaultLayout, ProfileLayout],

  setup() {
    const jobSeeker = usePage().props.jobSeeker;
    console.log(jobSeeker);
    const form = useForm({
      firstName: jobSeeker.firstName ?? '',
      lastName: jobSeeker.lastName ?? '',
      email: jobSeeker.email,
      mobilePhoneNumber: jobSeeker.mobilePhoneNumber ?? '',
      birthday: jobSeeker.birthday ?? '',
    });

    return {
      form,
    };
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.form.post(this.route('frontend.profile.my-information.update'), {
        onError: () => {
          this.$refs['error-modal'].open();
        },
        onFinish: () => {
          console.log(this.form);
          this.loading = false;
        },
      });
    },
  },
};
</script>
